<template>
  <div class="two-line-query">
    <div
      v-for="(item, idx) in configs"
      :key="idx"
      class="two-line-query__item"
      @click="onSearchClick(item, idx)"
    >
      <span class="two-line-query__item-title">{{ item.title }}</span>
      <van-icon
        name="zhankai"
        class-prefix="iconfont"
        class="two-line-query__item-icon"
      />
    </div>
    <select-popup
      :choose-value="popupValue"
      :show-filter="false"
      :data="popupData"
      :title="popupTitle"
      :visible="popupVisible"
      :popup-style="{
        height: '50vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
    <auto-complete-general
      title="工艺位号"
      :visible="nameVisible"
      :params="{
        orgCode: userInfo.orgCode,
        businessType: 3
      }"
      label-key="num"
      value-key="id"
      remote
      remote-key="indicatorNum"
      :popup-style="{ height: '50vh' }"
      :checked="queryInfo.indicatorId"
      :api="getIndicatorsTipAll"
      @confirm="onNameConfirm"
      @close="nameVisible = false"
    />
  </div>
</template>

<script>
import SelectPopup from "@/components/SelectPopup";
import { getTwoLinePerson, getIndicatorsTipAll } from "@/api/warning";
import { statueList, typeList } from "./utils/constant";
import AutoCompleteGeneral from "@/components/AutoCompleteGeneral";
export default {
  name: "TwoLineQuery",
  components: { AutoCompleteGeneral, SelectPopup },
  props: ["query"],
  data() {
    return {
      getIndicatorsTipAll,
      queryInfo: {
        operators: "",
        operate: "",
        approveStatus: "",
        indicatorId: ""
      },
      statueList,
      typeList,
      personList: [],
      bitNumList: [],
      configs: [
        {
          title: "操作人",
          type: "operators",
          types: "personList"
        },
        {
          title: "操作类型",
          type: "operate",
          types: "typeList"
        },
        {
          title: "工艺位号",
          type: "indicatorId",
          types: "bitNumList"
        },
        {
          title: "当前状态",
          type: "approveStatus",
          types: "statueList"
        }
      ],
      nameVisible: false,
      popupVisible: false,
      popupTitle: "",
      popupData: [],
      selectValue: "",
      popupValue: "",
      popupType: ""
    };
  },
  created() {
    this.initTypes();
  },
  methods: {
    refreshQuery() {
      this.$emit("refresh", this.queryInfo);
    },
    async initTypes() {
      try {
        this.personList = await getTwoLinePerson({
          orgCode: this.userInfo.orgCode
        });
      } catch (error) {
        console.log("initTypes -> error", error);
        this.personList = [];
      }
    },
    onNameConfirm(val) {
      this.queryInfo.indicatorId = val || "";
      this.refreshQuery();
    },
    onSearchClick({ title, type, types }, idx) {
      if (type === "indicatorId") {
        return (this.nameVisible = true);
      }
      this.popupValue = this.queryInfo[type] || "";
      this.popupType = type;
      this.popupData = this[types];
      this.popupTitle = title;
      this.popupVisible = true;
    },
    onPopupReset() {
      this.queryInfo[this.popupType] = "";
      this.popupValue = "";
      this.refreshQuery();
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    onPopupConfirm(row) {
      this.queryInfo[this.popupType] = row.value;
      this.refreshQuery();
    }
  }
};
</script>

<style lang="scss">
.two-line-query {
  display: flex;
  justify-content: space-between;
  padding: 12px 22px;
  margin-top: -2px;
  color: #3b4664;
  font-size: 14px;
  border-bottom: 1px solid #c7c9d0;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-icon {
      margin-left: 4px;
      color: #aeb3c0;
      font-size: 12px;
    }
  }
}
</style>
