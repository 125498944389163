var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"two-line-list"},[_c('van-nav-bar',{attrs:{"left-arrow":"","title":_vm.title},on:{"click-left":function($event){return _vm.$router.back()}}}),_c('div',{staticClass:"search-query"},[_c('date-time',{attrs:{"time-close":false,"type":"datetime","format":"{y}-{m}-{d} {h}:{i}"},model:{value:(_vm.dateTime),callback:function ($$v) {_vm.dateTime=$$v},expression:"dateTime"}}),_c('two-line-query',{attrs:{"query":_vm.queryInfo},on:{"refresh":_vm.onQueryChange}}),_c('div',{staticClass:"trouble-total"},[_vm._v("共"+_vm._s(_vm.total)+"条结果")])],1),_c('van-pull-refresh',{staticClass:"two-line-list__wrapper",on:{"refresh":_vm.onRefresh},model:{value:(_vm.isPullRefreshing),callback:function ($$v) {_vm.isPullRefreshing=$$v},expression:"isPullRefreshing"}},[_c('van-list',{attrs:{"finished":_vm.isFinished,"finished-text":_vm.isError ? '' : '没有更多了',"error":_vm.isError,"error-text":"加载失败，请稍后再试！"},on:{"update:error":function($event){_vm.isError=$event},"load":_vm.getList},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},_vm._l((_vm.list),function(item,idx){return _c('van-cell',{key:idx,class:[
          'two-line-list__rows',
          idx === _vm.list.length - 1 && 'border-none'
        ],attrs:{"border":false},on:{"click":function($event){return _vm.gotoDetails(item)}}},[_c('div',{staticClass:"item-wrapper"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"top"},[_vm._v(_vm._s(item.utime))]),_c('div',{staticClass:"middle"},[_c('div',{staticClass:"icon-group"},[_c('i',{class:[
                    'iconfont',
                    +item.operation === 1
                      ? 'iconfont-shangxian'
                      : 'iconfont-xiaxian'
                  ]}),_c('span',{staticClass:"value text-overflow"},[_vm._v(_vm._s(_vm.typeDic[item.operation] || "--"))])]),_c('div',{staticClass:"icon-group"},[_c('i',{staticClass:"iconfont iconfont-renyuan"}),_c('span',{staticClass:"value text-overflow"},[_vm._v(_vm._s(item.operatorName || "--"))])])]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"icon-group"},[_c('i',{staticClass:"iconfont iconfont-bumen"}),_c('span',{staticClass:"value text-overflow"},[_vm._v(_vm._s(item.departmentName || "--"))])]),_c('div',{staticClass:"icon-group"},[_c('i',{staticClass:"iconfont iconfont-renyuandingweibaojing"}),_c('span',{staticClass:"value text-overflow"},[_vm._v(_vm._s(item.indicatorTotal || 0))])])])]),_c('div',{staticClass:"status-icon",style:({ color: _vm.iconConf[item.approveStatus].color })},[_c('van-icon',{attrs:{"name":_vm.iconConf[item.approveStatus].name,"class-prefix":"iconfont"}})],1)]),_c('div',{staticClass:"line-block"})])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }