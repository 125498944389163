<template>
  <div class="two-line-list">
    <van-nav-bar left-arrow :title="title" @click-left="$router.back()" />
    <div class="search-query">
      <date-time
        v-model="dateTime"
        :time-close="false"
        type="datetime"
        format="{y}-{m}-{d} {h}:{i}"
      />
      <two-line-query :query="queryInfo" @refresh="onQueryChange" />
      <div class="trouble-total">共{{ total }}条结果</div>
    </div>
    <van-pull-refresh
      v-model="isPullRefreshing"
      class="two-line-list__wrapper"
      @refresh="onRefresh"
    >
      <van-list
        v-model="isLoading"
        :finished="isFinished"
        :finished-text="isError ? '' : '没有更多了'"
        :error.sync="isError"
        error-text="加载失败，请稍后再试！"
        @load="getList"
      >
        <van-cell
          v-for="(item, idx) in list"
          :key="idx"
          :class="[
            'two-line-list__rows',
            idx === list.length - 1 && 'border-none'
          ]"
          :border="false"
          @click="gotoDetails(item)"
        >
          <div class="item-wrapper">
            <div class="content">
              <div class="top">{{ item.utime }}</div>
              <div class="middle">
                <div class="icon-group">
                  <i
                    :class="[
                      'iconfont',
                      +item.operation === 1
                        ? 'iconfont-shangxian'
                        : 'iconfont-xiaxian'
                    ]"
                  />
                  <span class="value text-overflow">{{
                    typeDic[item.operation] || "--"
                  }}</span>
                </div>
                <div class="icon-group">
                  <i class="iconfont iconfont-renyuan" />
                  <span class="value text-overflow">{{
                    item.operatorName || "--"
                  }}</span>
                </div>
              </div>
              <div class="bottom">
                <div class="icon-group">
                  <i class="iconfont iconfont-bumen" />
                  <span class="value text-overflow">{{
                    item.departmentName || "--"
                  }}</span>
                </div>
                <div class="icon-group">
                  <i class="iconfont iconfont-renyuandingweibaojing" />
                  <span class="value text-overflow">{{
                    item.indicatorTotal || 0
                  }}</span>
                </div>
              </div>
            </div>
            <div
              class="status-icon"
              :style="{ color: iconConf[item.approveStatus].color }"
            >
              <van-icon
                :name="iconConf[item.approveStatus].name"
                class-prefix="iconfont"
              />
            </div>
          </div>
          <div class="line-block" />
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import TwoLineQuery from "@/views/TwoLine/TwoLineQuery";
import dayjs from "dayjs";
import { getPageTwoLine } from "@/api/warning";
import { iconConf, typeDic } from "./utils/constant";
export default {
  name: "OnOffLineList",
  components: { TwoLineQuery },
  data() {
    return {
      iconConf,
      typeDic,
      dateTime: { start: "", end: "" },
      queryInfo: {},
      total: 0,
      list: [],
      // 下拉刷新，上拉加载
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false,
      routerPath: ""
    };
  },
  watch: {
    dateTime: {
      handler(val) {
        if (val.start && val.end) {
          this.queryInfo.queryStartTime = this.formatDate(val.start);
          this.queryInfo.queryEndTime = this.formatDate(val.end);
          this.onRefresh();
        }
        if (!val.start && !val.end) {
          this.queryInfo.startTime = "";
          this.queryInfo.endTime = "";
          this.onRefresh();
        }
      },
      deep: true
    }
  },
  computed: {
    type() {
      return this.$route.meta?.type || 0;
    },
    title() {
      return +this.type === 1 ? "生产工艺点位" : "可燃有毒气体";
    }
  },
  created() {
    console.log(this.type, "type");
    const type = this.$route.meta?.type ?? 0;
    this.queryInfo.type = type;
    const routerMap = { 0: "/onoffline", 1: "/onoffline-process" };
    this.routerPath = routerMap[type] || "/onoffline";
    this.queryInfo.orgCode = this.userInfo.orgCode;
    this.onRefresh();
  },
  methods: {
    onQueryChange(query) {
      this.queryInfo = { ...this.queryInfo, ...query };
      this.onRefresh();
    },
    formatDate(date) {
      return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    },
    onRefresh() {
      this.queryInfo.page = 1;
      this.list = [];
      this.getList();
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        const data = await getPageTwoLine(this.queryInfo);
        const { list, total } = data || { list: [], total: 0 };
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        if (list && list.length) {
          this.list = this.list.concat(list);
          this.queryInfo.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        console.log("getList -> error", error);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    gotoDetails({ id }) {
      this.$router.push({ path: `${this.routerPath}/${id}` });
    }
  }
};
</script>

<style lang="scss">
.two-line-list {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .search-bar {
    padding-bottom: 0;
  }
  .trouble-total {
    text-align: center;
    color: #8c8f97;
    font-size: 14px;
    padding: 14px 0;
  }
  &__wrapper {
    overflow-y: auto;
  }
  &__rows {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    .van-cell__value .item-wrapper {
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .content {
        position: relative;
        width: 50vw;
        color: #3b4664;
        .top {
          color: #15171b;
          line-height: 20px;
          margin-bottom: 9px;
        }
        .middle {
          margin-bottom: 4px;
        }
        .middle,
        .bottom {
          display: flex;
          justify-content: space-between;
          .value {
            display: inline-block;
            font-size: 12px;
            line-height: 16px;
            vertical-align: middle;
            width: 60px;
          }
        }
      }
      .icon-group {
        line-height: 1;
        .iconfont {
          vertical-align: middle;
          color: #b9bac5;
          margin-right: 6px;
          font-size: 14px;
        }
      }
      .status-icon {
        float: right;
        i {
          display: block;
          line-height: 40px;
          font-size: 40px;
        }
      }
    }
    .line-block {
      margin: 0 -16px;
      margin-bottom: 0;
    }
  }
}
</style>
